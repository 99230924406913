import { NewTaskStatus, PriorityType, TaskStatus } from '@shared/enums';
import { AppDescType, NewAppType, NewInitiatorType, UserInfoType } from '@shared/types';

export const regExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const secretKey = 'XkhZG4fW2t2WA6Ry';

// export const baseUrl = 'https://1c.rixap.ru/hdwork/Hs/api/';
// export const baseUrl = 'https://1c.rixap.ru/hd_razrab/Hs/api/';
// export const baseUrl = 'https://1c.rixap.ru/hd_test/Hs/api/';
export const baseUrl = 'https://1c.rixap.ru/hd_rixap/Hs/api/';

const NameUID = { UID: '', Name: '' };

// export const ServiceName = 'Участок';
export const ServiceName = 'Сервис';
// export const ServiceShowName = 'Выбор участка';
export const ServiceShowName = 'Выбор сервиса';
// export const CategoryName = 'Сервис';
export const CategoryName = 'Категория';
// export const CategoryShowName = 'Выбор сервиса';
export const CategoryShowName = 'Выбор категории';

export const newAppDefaultDesk: NewAppType = {
  Subject: '',
  Initiator: NameUID,
  Executor: NameUID,
  Service: NameUID,
  Category: NameUID,
  Priority: PriorityType['medium'],
  Note: '',
  Status: NewTaskStatus['Appointed'],
  Address: '',
};

export const AppDescDefault: AppDescType = {
  UID: '',
  Number: '',
  Date: '',
  Status: TaskStatus['Draft'],
  Initiator: NameUID,
  Executor: NameUID,
  Coordinator: NameUID,
  Service: NameUID,
  Category: { UID: '00000000-0000-0000-0000-000000000000', Name: '' },
  Subject: '',
  Note: '',
  Priority: PriorityType['medium'],
  Comments: [],
  ReadOnly: true,
  ValidStatuses: [],
  Organization: NameUID,
  Address: '',
  isChanged: false,
};

export const UserDeskDefault: UserInfoType = {
  UID: '',
  FullName: '',
  Name: '',
  Organization: NameUID,
  Department: NameUID,
  Position: '',
  Priority: PriorityType['medium'],
  Address: '',
  Phone: '',
  Email: '',
  Telegramm: '',
  NotificationResolution: false,
  Comment: '',
  TakeApplications: false,
  Service: NameUID,
  Category: NameUID,
  Avatar: '',
};

export const NewInitiatorDefault: NewInitiatorType = {
  FullName: '',
  Name: '',
  Organization: NameUID,
  Department: NameUID,
  Position: '',
  Address: '',
  Email: '',
  PriorityDefault: PriorityType['medium'],
  NotificationResolution: true,
};
