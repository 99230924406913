import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AppDispatch, RootState } from '@redux/store';
import styles from './Applications.module.scss';
import { AppType } from '@shared/types';
import { TaskStatus } from '@shared/enums';
import { resetSomeFiltersFields, setFilterModalState } from '@redux/slices/filterSlices';
import { AuthContext } from '@contexts/CurrUserContext';
import { fetchSimple } from 'api';

import { Card } from '@components/UI/Card';
import Preloader from '@components/UI/Preloader/Preloader';
import { ModalAppFilter } from '@components/Modal/ModalAppFilter/ModalAppFilter';
import FilterInput from '@components/UI/FilterInput';
import ExtendButton from '@components/UI/ExtendButton';
import { useDebounce } from '@shared/hooks/useDebounce';

export const Applications = () => {
  const { application } = useParams();
  const { isOpen, isActive, servFilter, categoryFilter, statusFilter, isWithoutExecutor } =
    useSelector((state: RootState) => state.filter);
  const { isAppsRefreshing } = useSelector((state: RootState) => state.apps);
  const [appDesc, setAppDesc] = useState<string>('');
  const [currTasks, setCurrTasks] = useState<AppType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const debouncedValue = useDebounce<string>(appDesc, 500);
  const { logout } = useContext(AuthContext);

  // сборщик тела запроса из фильтров
  const appBodyReq = useCallback(() => {
    const reqBody = {};
    servFilter.UID && Object.assign(reqBody, { ApplicationServise: servFilter.UID });
    categoryFilter.UID && Object.assign(reqBody, { category: categoryFilter.UID });
    statusFilter.length && Object.assign(reqBody, { statusFilter: statusFilter });
    !!debouncedValue && Object.assign(reqBody, { search: debouncedValue });
    isWithoutExecutor && Object.assign(reqBody, { ApplicationTo: '' });
    return reqBody;
  }, [categoryFilter.UID, servFilter.UID, statusFilter, debouncedValue, isWithoutExecutor]);

  // обновление списка заявок при применении фильтра (всех кроме открытия и закрытия модального окна)
  useEffect(() => {
    setIsLoading(true);
    setCurrTasks([]);
    setCurrPage(1);
    dispatch(setFilterModalState(false));
    fetchSimple('Applications', { part: 1, ...appBodyReq() })
      .then((res) => setCurrTasks(res))
      .catch((err) => (err === 401 ? logout() : console.error(err)))
      .finally(() => setIsLoading(false));
    const updateInterval = localStorage.getItem('updateInterval');
    if (!!updateInterval && Number(updateInterval)) {
      const interval = setInterval(() => {
        fetchSimple('Applications', { part: 1, ...appBodyReq() })
          .then((res) => setCurrTasks([...currTasks, ...res]))
          .catch((err) => console.error(err));
      }, +updateInterval * 6000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appBodyReq, isAppsRefreshing]);

  const fetchMoreTasks = () => {
    setCurrPage((prev) => prev + 1);
    fetchSimple('Applications', { part: currPage + 1, ...appBodyReq() })
      .then((res) => {
        res.length === 0 && setIsEnd(true);
        setCurrTasks([...currTasks, ...res]);
      })
      .catch((err) => console.error(err));
  };

  const handleReverseArr = () =>
    setCurrTasks(
      currTasks
        .slice(0)
        .reverse()
        .map((element) => {
          return element;
        })
    );

  const handleOnClickByFilters = (v: 'free' | 'Appointed' | 'InProgs') => {
    switch (v) {
      case 'free':
        dispatch(resetSomeFiltersFields({ isWithoutExecutor: !isWithoutExecutor }));
        return;
      case 'Appointed':
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['Appointed']] }));
        return;
      default:
        dispatch(resetSomeFiltersFields({ statusFilter: [TaskStatus['InProgs']] }));
        return;
    }
  };

  return (
    <div className={cn(styles.info, isOpen && styles.info_blocked)} id="scrollableDiv">
      <FilterInput onChange={setAppDesc} />
      <div className={styles.heading}>
        <div className={styles.item} onClick={() => dispatch(setFilterModalState(true))}>
          <span className={cn(styles.filters, isActive && styles.filters_active)} />
          <p className={styles.text}>Фильтры</p>
        </div>
        <div className={styles.item} onClick={handleReverseArr}>
          <span className={styles.sorting} />
          <p className={styles.text}>Сортировка</p>
        </div>
      </div>

      <div className={styles.tagContainer}>
        <ScrollContainer className={styles.tags} vertical={false}>
          <button
            className={cn(styles.tag, isWithoutExecutor && styles.tag_active)}
            onClick={() => handleOnClickByFilters('free')}
          >
            Свободные
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['Appointed']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('Appointed')}
          >
            Новая
          </button>
          <button
            className={cn(
              styles.tag,
              statusFilter.includes(TaskStatus['InProgs']) && styles.tag_active
            )}
            onClick={() => handleOnClickByFilters('InProgs')}
          >
            В работе
          </button>
        </ScrollContainer>
      </div>

      <>
        {currTasks.length === 0 ? (
          <div className={styles.noApps}>
            {isLoading ? <Preloader /> : <p className={styles.text}>Заявок нет</p>}
          </div>
        ) : (
          <InfiniteScroll
            className={styles.cards}
            dataLength={currTasks.length}
            next={fetchMoreTasks}
            hasMore={!isEnd}
            loader={
              <div className={styles.preloader}>
                <Preloader />
              </div>
            }
            scrollThreshold="300px"
            scrollableTarget="scrollableDiv"
          >
            {currTasks.map((item) => (
              <Card
                key={item.UID}
                cardInfo={item}
                UID={item.UID}
                isActive={item.UID === application}
                onClickHandler={() => {}}
              />
            ))}
          </InfiniteScroll>
        )}
      </>

      {isOpen && <ModalAppFilter />}
    </div>
  );
};

export const ApplicationsModal = ({
  isAppListOpen,
  setAppListOpen,
}: {
  isAppListOpen: boolean;
  setAppListOpen: (v: boolean) => void;
}) => {
  return (
    <div className={styles.appContainer}>
      <ExtendButton
        onClick={() => setAppListOpen(!isAppListOpen)}
        direction={isAppListOpen ? 'left' : 'right'}
        backColor="#fff"
        className={cn(styles.extendButton, isAppListOpen && styles.extendButton_active)}
      />
      <div className={cn(styles.applications, isAppListOpen && styles.applications_open)}>
        <Applications />
      </div>
    </div>
  );
};
